<template>
  <div class="webBottom">
    <a class="IcpCss" href="https://beian.miit.gov.cn/" target="_blank">{{ ICP }}</a>
    <!--        <p class="Links">友情链接：<a href="http://www.hxydup.com/">浩轩视觉</a><a href="http://www.baiiyi.com/">百艺汇聚</a></p>-->
    <p class="Links"></p>
    <p v-for="(item, index) in this.languageList" :key="index">{{ item }}</p>
  </div>
</template>

<script>
import ChineseJson from '../assets/json/Chinese-Json.json'
import EnglishJson from '../assets/json/English-Json.json'

export default {
  name: 'WebBottom',
  data() {
    return {
      ChineseJson: JSON.parse(JSON.stringify(ChineseJson)), //中文配表
      EnglishJson: JSON.parse(JSON.stringify(EnglishJson)), //英文配表
      languageJson: [],
      languageList: [],
      langtype: null,
      ICP: null
    }
  },
  created() {
    // this.langtype = localStorage.getItem("LanguageTypes");
    // this.IFType();
    if (localStorage.getItem('LanguageTypes') == undefined) {
      this.langtype = 1
      this.PositionList = this.ChinesePosition
      this.IFType()
    } else {
      this.langtype = localStorage.getItem('LanguageTypes')
      this.IFType()
    }
  },
  methods: {
    IFType() {
      if (this.langtype == 1) {
        this.languageJson = this.ChineseJson
        this.getList()
      } else {
        this.languageJson = this.EnglishJson
        this.getList()
      }
    },
    getList() {
      // this.ChineseJson.fo
      this.languageJson.forEach((item) => {
        if (item.title == 'WebBottom') {
          this.languageList = item.List;
          this.ICP = item.ICP;
        }
      });
    },
    sing(data) {
      this.langtype = data
      this.IFType()
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.webBottom {
  width: 100%;
  height: 240px;
  box-sizing: border-box;
  background: #222222;
  position: relative;
}

.IcpCss {
  position: absolute;
  cursor: pointer;
  top: 190px;
  left: 48%;
  margin-left: -50px;
  color: #cecece;
  margin-right: 0.1rem;
  cursor: pointer;
}

.Links {
  position: absolute;
  cursor: pointer;
  top: 95px;
  left: 46%;
  margin-left: -50px;
}

.Links a {
  color: #cecece;
  margin-right: 0.1rem;
  cursor: pointer;
}
</style>
